"use client";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import { Autoplay, Grid, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./gallery_style.css";
import { AnimatePresence, motion } from "framer-motion";
import { Fragment, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { sliderGalleryImages } from "./images";
import styled from "styled-components";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";

export interface GalleryProps {
  src: string;
  description: string;
  id: number;
}
export const Gallery = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<GalleryProps | null>(null);

  const openModalHandler = (image: GalleryProps) => {
    setSelectedImage(image);
    setOpenModal(true);
  };
  useEffect(() => {
    if (typeof document === "undefined") return () => {};
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openModal]);
  return (
    <div
      style={{ display: "flex", height: "100%", width: "100%", gap: "30px" }}
    >
      <div
        className="swiper-button-prev"
        style={{ display: "flex", position: "relative", color: "white" }}
      >
        <MdArrowBackIosNew size={40} />
      </div>
      <Swiper
        breakpoints={{
          1020: {
            slidesPerView: 3,
            grid: { rows: 3 },
          },
          675: {
            slidesPerView: 2.3,
            grid: { rows: 3 },
          },
          0: {
            slidesPerView: 1.3,
            grid: { rows: 3 },
          },
        }}
        spaceBetween={30}
        pagination={{ clickable: true }}
        modules={[Grid, Pagination, Autoplay, Navigation]}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        loop={true}
        grabCursor
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        style={{ position: "relative", paddingBottom: "50px" }}
      >
        {sliderGalleryImages.map((item, index) => {
          const thumbnail = item.src.split(".");
          thumbnail[0] += "_thumb.";
          const src = thumbnail.join("");
          return (
            <SwiperSlide key={item.id}>
              <motion.div
                layoutId={item.id + ""}
                onClick={() => openModalHandler(item)}
                style={{ position: "relative", width: "100%", height: "100%" }}
              >
                <GridImage
                  src={src}
                  alt={item.description ?? "갤러리 이미지"}
                  width={321}
                  height={213}
                />
              </motion.div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div
        className="swiper-button-next"
        style={{ display: "flex", position: "relative", color: "white" }}
      >
        <MdArrowForwardIos size={40} />
      </div>
      <AnimatePresence mode="wait">
        {openModal && selectedImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: "fixed",
              top: "0px",
              left: "0px",
              backgroundColor: "black",
              width: "100%",
              height: "100%",
              zIndex: 100,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ModalBackground>
              <span style={{ fontSize: "25px", color: "white" }}>
                {selectedImage?.description}
              </span>
              <IoClose
                onClick={() => setOpenModal(false)}
                style={{
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  color: "white",
                }}
              />
            </ModalBackground>
            <motion.div
              style={{
                display: "flex",
                width: "100%",
                height: "calc(100% - 100px)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <motion.img
                layoutId={selectedImage.id + ""}
                src={selectedImage!.src}
                alt={selectedImage!.description ?? "갤러리 이미지"}
                width={321}
                height={200}
                style={{ objectFit: "contain", width: "100%", height: "100%" }}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const GridImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
`;

const ModalBackground = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 20px 30px 20px;
`;

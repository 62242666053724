import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

interface PopupProps {
  id: string;
  image: string;
  clickUrl?: string;
  index: number;
}

export const Popup = ({ image, id, clickUrl, index }: PopupProps) => {
  const [showPopup, setShowPopup] = useState(true);
  const [cookies, setCookies] = useCookies([`popup-${id}`]);

  useEffect(() => {
    const hasSeenPopupToday = cookies[`popup-${id}`];
    if (hasSeenPopupToday) {
      setShowPopup(false);
    }
  }, [cookies, id]);

  const handleClose = () => {
    setShowPopup(false);
    setCookies(`popup-${id}`, true, { maxAge: 86400 });
  };

  return (
    <AnimatePresence>
      <motion.div
        key={`popup-${id}`}
        initial={{ opacity: 0, display: "none" }}
        animate={
          showPopup
            ? { opacity: 1, display: "block" }
            : { opacity: 0, transitionEnd: { display: "none" } }
        }
        exit={{ opacity: 0, display: "none" }}
      >
        <Container $index={index}>
          <img
            src={image}
            alt="취업지원 프로그램 안내 이미지"
            style={{
              cursor: clickUrl ? "pointer" : "auto",
              objectFit: "contain",
            }}
            onClick={() => {
              if (clickUrl) {
                window.open(clickUrl, "_blank");
              }
            }}
          />
          <InputContainer>
            <Button onClick={handleClose}>오늘 하루 열지 않기</Button>
            <Button onClick={() => setShowPopup(false)}>닫기</Button>
          </InputContainer>
        </Container>
      </motion.div>
    </AnimatePresence>
  );
};

const Container = styled.div<{ $index: number }>`
  position: absolute;
  top: 100px;
  left: ${(props) => props.$index * 100 + 50}px;
  display: flex;
  flex-direction: column;
  /* min-width: calc(10vw); */
  max-width: 600px;
  width: calc(100vw - 150px);
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #909090;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  :first-child {
    border-right: 1px solid #909090;
  }
`;

const Button = styled.button`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  cursor: pointer;
  background-color: #e0e0e0;
  color: black;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const sliderGalleryImages = [
  {
    id: 27,
    src: "assets/gallery/이글루코퍼레이션 보안관제센터 전경.webp",
    description: "이글루코퍼레이션 보안관제센터 전경",
  },
  {
    id: 26,
    src: "assets/gallery/교육장 약도.webp",
    description: "교육장 약도",
  },
  {
    id: 25,
    src: "assets/gallery/교육장 외부 전경.webp",
    description: "교육장 외부 전경",
  },
  {
    id: 24,
    src: "assets/gallery/24년도 AI 보안관제 전문인력 양성_개최안내_카드뉴스_1.webp",
    description: "AI 보안관제 전문인력 양성교육 개최안내 카드뉴스 1",
  },
  {
    id: 23,
    src: "assets/gallery/24년도 AI 보안관제 전문인력 양성_개최안내_카드뉴스_2.webp",
    description: "AI 보안관제 전문인력 양성교육 개최안내 카드뉴스 2",
  },
  {
    id: 22,
    src: "assets/gallery/24년도 AI 보안관제 전문인력 양성_개최안내_카드뉴스_3.webp",
    description: "AI 보안관제 전문인력 양성교육 개최안내 카드뉴스 3",
  },
  {
    id: 21,
    src: "assets/gallery/AI 보안관제 전문인력 양성교육 착수보고회 1.webp",
    description: "AI 보안관제 전문인력 양성교육 착수보고회 1",
  },
  {
    id: 20,
    src: "assets/gallery/AI 보안관제 전문인력 양성교육 착수보고회 2.webp",
    description: "AI 보안관제 전문인력 양성교육 착수보고회 2",
  },
  {
    id: 19,
    src: "assets/gallery/AI 보안관제 전문인력 양성교육 착수보고회 3.webp",
    description: "AI 보안관제 전문인력 양성교육 착수보고회 3",
  },
  {
    id: 18,
    src: "assets/gallery/강의장1.webp",
    description: "AI 보안관제 전문인력 양성교육 판교 교육장 1",
  },
  {
    id: 17,
    src: "assets/gallery/강의장2.webp",
    description: "AI 보안관제 전문인력 양성교육 판교 교육장 2",
  },
  {
    id: 16,
    src: "assets/gallery/강의장3.webp",
    description: "AI 보안관제 전문인력 양성교육 판교 교육장 3",
  },
  {
    id: 15,
    src: "assets/gallery/강의장4.webp",
    description: "AI 보안관제 전문인력 양성교육 판교 교육장 4",
  },
  {
    id: 14,
    src: "assets/gallery/강의장5.webp",
    description: "AI 보안관제 전문인력 양성교육 판교 교육장 5",
  },
  {
    id: 10,
    src: "assets/gallery/6.webp",
    description: "",
  },
  {
    id: 9,
    src: "assets/gallery/7.webp",
    description: "",
  },
  {
    id: 8,
    src: "assets/gallery/8.webp",
    description: "",
  },
  {
    id: 7,
    src: "assets/gallery/9.webp",
    description: "",
  },
  {
    id: 6,
    src: "assets/gallery/10.webp",
    description: "",
  },
  {
    id: 5,
    src: "assets/gallery/11.webp",
    description: "",
  },
  {
    id: 4,
    src: "assets/gallery/12.webp",
    description: "",
  },
  {
    id: 3,
    src: "assets/gallery/13.webp",
    description: "",
  },
  {
    id: 2,
    src: "assets/gallery/14.webp",
    description: "",
  },
  {
    id: 1,
    src: "assets/gallery/15.webp",
    description: "",
  },
];

import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

export const Header = () => {
  const { pathname } = useLocation();

  return (
    <Wrap>
      <HeaderImage src={"assets/header-no-button-70.png"} alt="header" />
      <Container>
        <Link to="/">
          <Button>
            <img
              src={"assets/home/header_button.png"}
              alt="go home page button"
            />
          </Button>
        </Link>
        <Link to="/introduction">
          <Button>
            <img
              src={`assets/introduction/header_button${
                pathname === "/introduction" ? "_hover" : ""
              }.png`}
              alt="go introduction page button"
            />
          </Button>
        </Link>
        <Link to="/curriculum">
          <Button>
            <img
              src={`assets/curriculum/header_button${
                pathname === "/curriculum" ? "_hover" : ""
              }.png`}
              alt="go curriculum page button"
            />
          </Button>
        </Link>
        <Link to="/environment">
          <Button>
            <img
              src={`assets/environment/header_button${
                pathname === "/environment" ? "_hover" : ""
              }.png`}
              alt="go environment page button"
            />
          </Button>
        </Link>
        <Link to="/partner">
          <Button>
            <img
              src={`assets/partner/header_button${
                pathname === "/partner" ? "_hover" : ""
              }.png`}
              alt="go faq page button"
            />
          </Button>
        </Link>

        <Link to="/faq">
          <Button>
            <img
              src={`assets/faq/header_button${
                pathname === "/faq" ? "_hover" : ""
              }.png`}
              alt="go faq page button"
            />
          </Button>
        </Link>
        <Link to="/apply">
          <Button>
            <img
              src={`assets/apply/header_button.png`}
              alt="go apply page button"
            />
          </Button>
        </Link>
      </Container>
    </Wrap>
  );
};

const Wrap = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 10px 0px 10px 0px;
  img {
    width: 100%;
  }
`;

const Container = styled.nav`
  display: flex;
  align-items: center;
  max-width: 1350px;
  height: 100%;
  width: 100%;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: fit-content;
`;

const HeaderImage = styled.img`
  position: absolute;
  inset: 0;
  max-height: 150px;
  width: 100%;
  height: 100%;
  z-index: -10;
`;

import { useEffect, useState } from "react";
import { PartnerCard } from "./partnerCard";
import { Loading } from "./loading";

type PartnerType = {
  id: number;
  company: string;
  url: string;
  imageUrl: string;
  sequence: number;
  created_at: string;
  updated_at: string;
};
export const PartnerSection = () => {
  const [isFlip, setIsFlip] = useState<false | number>(false);
  const [partnerList, setPartnerList] = useState<PartnerType[] | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const fetchPartnerList = async () => {
      const result = await fetch(
        "https://stage.d30cxj7hm6j7z9.amplifyapp.com/api/partner"
      ).then((res) => res.json());
      if (result) {
        setPartnerList(result.partnerList);
        setIsLoaded(true);
      }
    };
    fetchPartnerList();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        paddingTop: "40px",
        paddingBottom: "40px",
        justifyContent: "center",
      }}
    >
      {!isLoaded && <Loading />}
      {partnerList?.map((value, index) => {
        return (
          <PartnerCard
            key={index}
            image={value.imageUrl}
            name={value.company}
            url={value.url}
            index={index}
            isFlip={isFlip}
            setIsFlip={setIsFlip}
          />
        );
      })}
    </div>
  );
};

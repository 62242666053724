import styled from "styled-components";
import { PageTemplate } from "./main";
import { Loading } from "../components/loading";
import { useState } from "react";
import { TopMainImageWrap } from "./environment";

export const Introduction = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <Wrap>
      <MainImageWrap>
        {!isImageLoaded && <Loading />}
        <TopMainImageWrap>
          <img src={"assets/5-environment-top.png"} alt="environment_main" />
        </TopMainImageWrap>
        <MainImage
          src={"assets/introduction/main.webp"}
          alt="introduction page body"
          onLoad={() => setIsImageLoaded(true)}
          style={{ display: isImageLoaded ? "block" : "none" }}
        />
      </MainImageWrap>
    </Wrap>
  );
};

const Wrap = styled(PageTemplate)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainImageWrap = styled(PageTemplate)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1920px;
`;

const MainImage = styled.img`
  max-width: 1600px;
`;

import styled from "styled-components";
import { PartnerSection } from "../components/partnerSection";
import { PageTemplate } from "./main";

export const Partner = () => {
  return (
    <Wrap>
      <TopMainImageWrap>
        <img src={"assets/5-environment-top.png"} alt="environment_main" />
        <img
          src={"assets/6-partner-title.png"}
          style={{ width: "45%" }}
          alt="협약기업"
        />
        <ButtonContainer>
          <img
            onClick={() =>
              window.open(
                "https://kshieldjr.s3.ap-northeast-2.amazonaws.com/%EC%A0%95%EB%B3%B4%EB%B3%B4%ED%98%B8_%EC%9A%B0%EC%88%98_%EC%9D%B8%EC%9E%AC_%EC%96%91%EC%84%B1_%ED%98%91%EC%95%BD%EC%84%9C(%EC%98%A8%EB%9D%BC%EC%9D%B8%EB%B0%B0%ED%8F%AC).hwp",
                "_blank",
                "rel=noopener noreferrer"
              )
            }
            style={{ width: "25%", cursor: "pointer" }}
            src={"assets/6-partner-doc-button.png"}
            alt="협약서 다운로드"
          />
          <img
            onClick={() =>
              window.open(
                "https://kshieldjr.s3.ap-northeast-2.amazonaws.com/%ED%98%91%EC%95%BD%EA%B8%B0%EC%97%85_%EB%AA%A9%EB%A1%9D.xlsx",
                "_blank",
                "rel=noopener noreferrer"
              )
            }
            style={{ width: "25%", cursor: "pointer" }}
            src={"assets/6-partner-list-button.png"}
            alt="협약기업 명단 다운로드"
          />
        </ButtonContainer>
        <PartnerSection />
      </TopMainImageWrap>
    </Wrap>
  );
};
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  /* width: 100%; */
  justify-content: right;
  margin-top: clamp(20px, 5vw, 100px);
`;
const Wrap = styled(PageTemplate)`
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const TopMainImageWrap = styled.div`
  max-width: 1600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > img {
    width: 100%;
  }
`;

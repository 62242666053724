import { motion, Transition } from "framer-motion";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
interface PartnerCardProps {
  index: number;
  image: string;
  name: string;
  url: string;
  isFlip: false | number;
  setIsFlip: Dispatch<SetStateAction<false | number>>;
}
export const PartnerCard = ({
  index,
  image,
  name,
  url,
  isFlip,
  setIsFlip,
}: PartnerCardProps) => {
  const isCurrent = isFlip === index;
  const flip: Transition = {
    type: "spring",
    stiffness: 300,
    damping: 40,
  };
  return (
    <motion.div
      onClick={() => setIsFlip(isCurrent ? false : index)}
      transition={flip}
      style={{
        perspective: "1200px",
        transformStyle: "preserve-3d",
        display: "flex",
        width: "192px",
        height: "128px",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <PartnerCardStyle
        animate={{ rotateY: isCurrent ? -180 : 0 }}
        transition={flip}
        style={{
          zIndex: isCurrent ? 0 : 1,
        }}
      >
        <img
          className="object-contain aspect-video"
          src={image}
          alt="logo"
          width={220}
          height={100}
          style={{
            objectFit: "contain",
            width: "90%",
            pointerEvents: "none",
          }}
        />
      </PartnerCardStyle>
      <PartnerCardBack
        className="flex flex-col w-full h-full justify-center items-center ring-2 ring-gray-300 hover:ring-primary gap-2"
        initial={{ rotateY: 180 }}
        animate={{ rotateY: isCurrent ? 0 : 180 }}
        transition={flip}
        style={{
          zIndex: isCurrent ? 1 : 0,
        }}
      >
        <span
          style={{
            display: "block",
            textAlign: "center",
            wordBreak: "keep-all",
          }}
        >
          {name}
        </span>
        <span
          style={{
            cursor: "pointer",
            padding: "8px 20px 8px 20px",
            backgroundColor: "#00579E",
            color: "#ffffff",
            borderRadius: "10px",
          }}
          onClick={() => window.open(url)}
        >
          바로가기
        </span>
      </PartnerCardBack>
    </motion.div>
  );
};

const PartnerCardStyle = styled(motion.div)`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 3px solid #ffffff;
  border-radius: 6px;
  background-color: #ffffff;
  backface-visibility: hidden;
  transition: border-color 0.3s;
  &:hover {
    border-color: #00579e;
  }
`;
const PartnerCardBack = styled(PartnerCardStyle)`
  position: absolute;
  flex-direction: column;
  gap: 10px;
`;
